<template>
  <div id="receta" class="receta">
    <div v-if="loading" class="text-center">
      <v-progress-circular :size="60" color="primary" indeterminate />
    </div>
    <div v-else>
      <v-container grid-list-xl fluid>
        <v-layout wrap>
          <v-flex lg12 md12>
            <!-- Vista mobile -->
            <v-card class="hidden-md-and-up">
              <center><img :src="receta?.profesional?.img_receta" style="max-width: 300px;max-height: 100px" /></center>
              <v-layout wrap style="padding: 0px 8px">
                <v-flex xs12>
                  <span class="texto_prescripcion">
                    <b>{{ $t('recetas.prescripcion') }}</b> {{ $t('recetas.prescripcion_detalle') }}
                  </span>
                </v-flex>
                <v-flex xs12 sm5>
                  {{ $t('recetas.num_envases') }}:&nbsp;<input
                    v-model="datos_receta.envases"
                    class="input-numero-mobile"
                    type="number"
                    step="1"
                    @change="modificaDato"
                  />
                </v-flex>
                <v-flex xs12 sm7>
                  <span v-if="datos_receta.medicamento.id > 0" class="datos_paciente">
                    <span style="float: right; cursor: pointer" @click="quitarMedicamento()"
                      ><v-icon>mdi-close</v-icon></span
                    >
                    {{ datos_receta.medicamento.nombre }}<br />
                  </span>
                  <span v-else>
                    <v-menu offset-y style="width: 100%" nudge-bottom="0" nudge-left="0" nudge-right="0" nudge-top="0">
                      <template v-slot:activator="{ on }">
                        <input
                          v-model="datos_receta.medicamento.nombre"
                          placeholder="Buscar Medicamento"
                          type="text"
                          class="input-texto-mobile"
                          style="text-align: left; width: 100%"
                          v-on="on"
                          @input="onChangeSearchVal"
                        />
                      </template>

                      <v-list :style="datos_receta.medicamento.nombre.length > 2 ? '' : 'display:none'">
                        <v-list-item v-for="(item, key) in medicamentos" :key="key">
                          <v-list-item-title v-if="item.buscando">
                            <center>
                              <v-progress-circular :width="2" :size="20" indeterminate color="primary" />
                            </center>
                          </v-list-item-title>
                          <v-list-item-title v-else @click="seleccionarMedicamento(item.id)">{{
                            item.name
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </span>
                </v-flex>
                <v-flex xs12 sm6>
                  <input
                    v-model="datos_receta.duracion"
                    :placeholder="$t('recetas.duracion')"
                    class="input-texto-mobile"
                    style="width: 99%"
                    type="text"
                    @change="modificaDato"
                  />
                </v-flex>
                <v-flex v-if="false" xs12 sm6>
                  <input
                    v-model="datos_receta.posologia"
                    class="input-texto-mobile"
                    :placeholder="$t('recetas.posologia')"
                    style="width: 100%"
                    type="text"
                    @change="modificaDato"
                  />
                </v-flex>
                <v-flex xs6>
                  <input
                    v-model="datos_receta.unidades"
                    class="input-texto-mobile"
                    :placeholder="$t('recetas.unidades')"
                    style="width: 100%"
                    type="text"
                    @change="modificaDato"
                  />
                </v-flex>
                <v-flex xs6>
                  <input
                    v-model="datos_receta.pauta"
                    class="input-texto-mobile"
                    :placeholder="$t('recetas.pauta')"
                    style="width: 100%"
                    type="text"
                    @change="modificaDato"
                  />
                </v-flex>
                <v-flex xs12>
                  <b>{{ $t('common.paciente') }}</b
                  >&nbsp;({{ $t('recetas.nombreaniodni') }})<br />
                  <span v-if="receta.paciente.id > 0" class="datos_paciente">
                    <span style="float: right; cursor: pointer" @click="quitarPaciente()"
                      ><v-icon>mdi-close</v-icon></span
                    >
                    {{ receta.paciente.nombre }}<br />
                    {{ receta.paciente.fechaNacimiento }}<br />
                    {{ receta.paciente.dni }}
                  </span>
                  <span v-else>
                    <v-menu offset-y style="width: 100%" nudge-bottom="0" nudge-left="0" nudge-right="0" nudge-top="0">
                      <template v-slot:activator="{ on }">
                        <input
                          v-model="receta.paciente.nombre"
                          type="text"
                          class="input-texto-mobile"
                          style="text-align: left; width: 100%"
                          v-on="on"
                          @input="buscaPacienteReceta()"
                        />
                      </template>

                      <v-list :style="receta.paciente.nombre.length > 2 ? '' : 'display:none'">
                        <v-list-item v-for="(item, key) in pacientes" :key="key">
                          <v-list-item-title v-if="item.buscando">
                            <center>
                              <v-progress-circular :width="2" :size="20" indeterminate color="primary" />
                            </center>
                          </v-list-item-title>
                          <v-list-item-title v-else @click="seleccionarPaciente(item.id)">{{
                            item.fullName
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </span>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="datos_receta.info_farmaceutico"
                    :label="$t('recetas.info_farmaceutico')"
                    @change="modificaDato"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="receta.instrucciones_paciente"
                    :label="$t('recetas.instrucciones_paciente')"
                    @change="modificaDato"
                  />
                </v-flex>
              </v-layout>
            </v-card>
            <!-- Vista Pantallas grandes -->
            <v-card class="mb-4 hidden-sm-and-down">
              <v-layout wrap>
                <v-flex xs3>
                  <v-btn color="blue darken-2" icon text @click="volverAtras()"
                    ><v-icon style="font-size: 30px">mdi-arrow-left</v-icon></v-btn
                  >
                </v-flex>
                <v-flex xs9 class="text-right">
                  <v-btn
                    class="btn-separation"
                    color="success"
                    :disabled="disabledButton"
                    depressed=""
                    @click="generarVistaPrevia(true)"
                  >
                    <v-icon>mdi-printer</v-icon>&nbsp;{{ $t('recetas.imprimir') }}
                  </v-btn>

                  <v-btn
                    class="btn-separation"
                    color="success"
                    :disabled="disabledButton"
                    depressed
                    @click="generarVistaPrevia(false)"
                  >
                    {{ $t('recetas.generar') }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 class="text-center">
                  <img
                    :src="receta?.profesional?.img_receta"
                    style="margin: -50px 0px -5px 0px;max-width: 400px; max-height: 150px"
                  />
                </v-flex>
              </v-layout>
              <center>
                <table border="2" class="tabla_receta" style="max-width: 1000px">
                  <tr>
                    <td colspan="6" style="text-align: center; font-size: 16px">
                      {{ $t('recetas.receta_medica_asistencia_sanitaria_privada') }}
                    </td>
                  </tr>
                  <tr>
                    <td colpan="1" class="celda_1_1">
                      <div class="texto-vertical-2">{{ $t('recetas.receta_medica_privada') }}</div>
                    </td>
                    <td colpan="5" style="vertical-align: top">
                      <table class="contenido_receta">
                        <tr style="height: 300px">
                          <td class="td_prescripcion borde_derecho">
                            <span class="texto_prescripcion">
                              <b>{{ $t('recetas.prescripcion') }}</b> {{ $t('recetas.prescripcion_detalle') }} </span
                            ><br clear="all" /><br />
                            {{ $t('recetas.num_envases') }}:
                            <input
                              v-model="datos_receta.envases"
                              class="input-numero"
                              type="number"
                              step="1"
                              @change="modificaDato"
                            />
                            <br />
                            <span v-if="datos_receta.medicamento.id > 0" class="datos_paciente">
                              <span style="float: right; cursor: pointer" @click="quitarMedicamento()"
                                ><v-icon>mdi-close</v-icon></span
                              >
                              {{ datos_receta.medicamento.nombre }}<br />
                            </span>
                            <span v-else>
                              <v-menu
                                offset-y
                                style="width: 95%"
                                nudge-bottom="0"
                                nudge-left="0"
                                nudge-right="0"
                                nudge-top="0"
                              >
                                <template v-slot:activator="{ on }">
                                  <input
                                    v-model="datos_receta.medicamento.nombre"
                                    :placeholder="$t('recetas.buscar_medicamento')"
                                    type="text"
                                    class="input-texto-corto"
                                    style="text-align: left; width: 100%"
                                    v-on="on"
                                    @input="onChangeSearchVal"
                                  />
                                </template>

                                <v-list :style="datos_receta.medicamento.nombre.length > 2 ? '' : 'display:none'">
                                  <v-list-item v-for="(item, key) in medicamentos" :key="key">
                                    <v-list-item-title v-if="item.buscando">
                                      <center>
                                        <v-progress-circular :width="2" :size="20" indeterminate color="primary" />
                                      </center>
                                    </v-list-item-title>
                                    <v-list-item-title v-else @click="seleccionarMedicamento(item.id)">{{
                                      item.name
                                    }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </span>
                          </td>
                          <td class="td_duracion borde_derecho">
                            <table class="contenido_receta">
                              <tr class="celda_interior borde_suelo" style="height: 75px;">
                                <td style="vertical-align: top">
                                  {{ $t('recetas.duracion') }}<br />
                                  <input
                                    v-model="datos_receta.duracion"
                                    class="input-texto-corto"
                                    style="width: 90%"
                                    type="text"
                                    @change="modificaDato"
                                  />
                                </td>
                              </tr>
                              <tr class="celda_interior borde_suelo" style="height: 45px;">
                                <td style="vertical-align: bottom;">
                                  <span style="text-transform: uppercase; font-weight: bold">{{
                                    $t('recetas.posologia')
                                  }}</span>
                                  <!--<input @change="modificaDato" class="input-texto-corto" style="width: 90%" type="text" v-model="datos_receta.posologia"/>-->
                                </td>
                              </tr>
                              <tr class="celda_interior borde_suelo" style="height: 75px;">
                                <td style="vertical-align: top">
                                  <table class="contenido_receta">
                                    <tr>
                                      <td width="50%" style="height: 75px; vertical-align: top" class="borde_derecho">
                                        {{ $t('recetas.unidades') }}<br />
                                        <input
                                          v-model="datos_receta.unidades"
                                          class="input-texto-corto"
                                          style="width: 90%"
                                          type="text"
                                          @change="modificaDato"
                                        />
                                      </td>
                                      <td width="50%" style="vertical-align: top">
                                        {{ $t('recetas.pauta') }}<br />
                                        <input
                                          v-model="datos_receta.pauta"
                                          class="input-texto-corto"
                                          style="width: 90%"
                                          type="text"
                                          @change="modificaDato"
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td style="padding-top: 25px;">
                                  {{ $t('recetas.fecha_prevista_dispensacion') }}:
                                  <input
                                    v-model="datos_receta.fecha_prevista"
                                    class="input-texto-corto"
                                    style="width: 90%"
                                    type="text"
                                  />
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td class="td_paciente">
                            <table class="contenido_receta">
                              <tr class="borde_suelo">
                                <td class="celda_paciente">
                                  <b>{{ $t('common.paciente') }}</b
                                  >&nbsp;({{ $t('recetas.nombreaniodni') }})<br />
                                  <span v-if="receta.paciente.id > 0" class="datos_paciente">
                                    <span style="float: right; cursor: pointer" @click="quitarPaciente()"
                                      ><v-icon>mdi-close</v-icon></span
                                    >
                                    {{ receta.paciente.nombre }}<br />
                                    {{ receta.paciente.fechaNacimiento }}<br />
                                    {{ receta.paciente.dni }}
                                  </span>
                                  <span v-else>
                                    <v-menu
                                      offset-y
                                      style="width: 95%"
                                      nudge-bottom="0"
                                      nudge-left="0"
                                      nudge-right="0"
                                      nudge-top="0"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <input
                                          v-model="receta.paciente.nombre"
                                          type="text"
                                          class="input-texto-corto"
                                          style="text-align: left; width: 100%"
                                          v-on="on"
                                          @input="buscaPacienteReceta()"
                                        />
                                      </template>

                                      <v-list :style="receta.paciente.nombre.length > 2 ? '' : 'display:none'">
                                        <v-list-item v-for="(item, key) in pacientes" :key="key">
                                          <v-list-item-title v-if="item.buscando">
                                            <center>
                                              <v-progress-circular
                                                :width="2"
                                                :size="20"
                                                indeterminate
                                                color="primary"
                                              />
                                            </center>
                                          </v-list-item-title>
                                          <v-list-item-title v-else @click="seleccionarPaciente(item.id)">{{
                                            item.fullName
                                          }}</v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td style="padding: 5px">
                                  <b>PRESCRIPTOR</b>&nbsp;(datos de identificación y firma)<br />
                                  <span class="datos_profesional">
                                    {{ receta.profesional.nombre }}<br />
                                    <span
                                      v-if="
                                        receta.profesional.titulacion !== '' && receta.profesional.colegiado !== null
                                      "
                                      >Núm. Colegiado: {{ receta.profesional.colegiado }}<br
                                    /></span>

                                    <span
                                      v-if="
                                        receta.profesional.titulacion !== '' && receta.profesional.titulacion !== null
                                      "
                                      >{{ receta.profesional.titulacion }}<br
                                    /></span>

                                    <span
                                      v-if="
                                        receta.profesional.titulacion !== '' && receta.profesional.domicilio !== null
                                      "
                                      >{{ receta.profesional.domicilio }}</span
                                    >
                                  </span>
                                  <img v-if="receta.firma !== ''" class="firma_receta" :src="receta.firma" />
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td class="td_informacion_farmaceutico borde_derecho borde_arriba">
                            <v-textarea
                              v-model="datos_receta.info_farmaceutico"
                              :label="$t('recetas.info_farmaceutico')"
                              @change="modificaDato"
                            />
                          </td>
                          <td colspan="2" class="td_informacion_farmaceutico borde_arriba">
                            <v-textarea
                              v-model="datos_receta.instrucciones_paciente"
                              :label="$t('recetas.instrucciones_paciente')"
                              @change="modificaDato"
                            />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>

                <br clear="all" />
              </center>
            </v-card>
            <div class="text-center">
              <br />
              <v-btn
                class="btn-separation"
                color="success"
                :disabled="disabledButton"
                depressed=""
                @click="generarVistaPrevia(true)"
              >
                <v-icon>mdi-printer</v-icon>&nbsp;{{ $t('recetas.imprimir') }}
              </v-btn>

              <v-btn color="success" :disabled="disabledButton" depressed="" @click="generarVistaPrevia(false)">
                <v-icon>mdi-send</v-icon>&nbsp;{{ $t('recetas.generar') }}
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
    <!-- Dialog Salir sin guardar -->
    <div class="text-center">
      <v-dialog v-model="dialogSalirSinGuardar" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
          <v-divider />
          <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{ $t('common.cancel') }}</v-btn>
            <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {
  generarVistaPreviaRecetaUrl,
  getListadoPacientesUrl,
  getListadoMedicamentosUrl,
  nuevaRecetaUrl,
  getHeader,
} from '../config/config';
import { debounceMixin } from '@/mixins/debounceMixin';

export default {
  components: {},
  mixins: [debounceMixin],
  data: () => ({
    dialogSalirSinGuardar: false,
    pageNext: {},
    esta_modificando: false,
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    disabledButton: false,
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
      tab: 2,
    },
    receta: {},
    datos_receta: {
      medicamento: { id: 0, nombre: '' },
      envases: 0,
      duracion: '',
      posologia: '',
      unidades: '',
      pauta: '',
      fecha_prevista: '',
      info_farmaceutico: '',
      instrucciones_paciente: '',
    },
    paciente_por_parametro: false,
    medicamentos: [],
    pacientes: [],
    medicamentos_todos: [],
    pacientes_todos: [],
  }),
  created() {
    this.paciente_por_parametro = this.$route.params.id !== undefined ? true : false;
    this.reemitir = this.$route.params.idreceta !== undefined ? true : false;
    this.$http
      .post(
        nuevaRecetaUrl,
        { user_id: this.authUser.id, paciente_id: this.$route.params.id, receta_id: this.$route.params.idreceta },
        { headers: getHeader() }
      )
      .then(response => {
        if (response.data.firma === '') {
          this.$router.push('/ajustes/firma');
        } else if (response.data.talonario === '') {
          this.$router.push('/ajustes/talonario');
        } else {
          this.receta = response.data.datos;
          if (response.data.aviso_pocas_recetas) {
            this.alerta.texto = this.$t('recetas.aviso_pocas_recetas', { num: response.data.num_recetas });
            this.alerta.icono = 'mdi-information';
            this.alerta.color = 'info';
            this.timeout = 9000;
            this.snackbar = true;
          }
          if (this.reemitir) {
            this.datos_receta.envases = response.data.receta.envases;
            this.datos_receta.medicamento.id = response.data.receta.medicamento_id;
            this.datos_receta.medicamento.nombre = response.data.receta.medicamento;
            this.datos_receta.duracion = response.data.receta.duracion;
            this.datos_receta.posologia = response.data.receta.posologia;
            this.datos_receta.unidades = response.data.receta.unidades;
            this.datos_receta.pauta = response.data.receta.pauta;
            this.datos_receta.fecha_prevista = response.data.receta.fechaPrevista;
            this.datos_receta.info_farmaceutico = response.data.receta.infoFarmaceutico;
            this.datos_receta.instrucciones_paciente = response.data.receta.instrucciones;
          }
        }
        this.loading = false;
      });
  },

  methods: {
    quitarMedicamento() {
      this.datos_receta.medicamento.id = 0;
      this.datos_receta.medicamento.nombre = '';
    },

    buscaPacienteReceta() {
      this.pacientes = [];
      if (this.receta.paciente.nombre.length === 3 || !isNaN(this.receta.paciente.nombre)) {
        this.pacientes.push({ buscando: true });
        this.$http
          .post(
            getListadoPacientesUrl,
            { filtro: this.receta.paciente.nombre, desdereceta: true },
            { headers: getHeader() }
          )
          .then(response => {
            this.pacientes_todos = response.data;
            this.pacientes = response.data;
          });
      } else if (this.receta.paciente.nombre.length > 3) {
        const pacientes_filtros = [];
        const nombre = this.receta.paciente.nombre.toUpperCase();
        this.pacientes_todos.forEach(function(pac) {
          const fullname = pac.fullName.toUpperCase();
          if (fullname.indexOf(nombre) > -1) {
            pacientes_filtros.push(pac);
          }
        });
        this.pacientes = pacientes_filtros;
      }
    },

    buscaMedicamentosReceta() {
      this.medicamentos = [];
      this.medicamentos.push({ buscando: true });
      this.$http
        .post(getListadoMedicamentosUrl, { filtro: this.datos_receta.medicamento.nombre }, { headers: getHeader() })
        .then(response => {
          console.log("Medicamentos")
          console.log(response.data)

          this.medicamentos = response.data;
        });
    },

    quitarPaciente() {
      this.receta.paciente.id = 0;
      this.receta.paciente.nombre = '';
      this.receta.paciente.fechaNacimiento = '';
      this.receta.paciente.dni = '';
    },

    seleccionarPaciente(id) {
      const paciente_selec = {};
      this.pacientes.forEach(function(pac) {
        if (pac['id'] === id) {
          paciente_selec.id = id;
          paciente_selec.nombre = pac['fullName'];
          paciente_selec.fechaNacimiento = pac['fechaNacimiento'];
          paciente_selec.dni = pac['dni'];
        }
      });
      this.receta.paciente = paciente_selec;
      this.esta_modificando = true;
    },

    seleccionarMedicamento(id) {
      const medicamento_selec = {};
      this.medicamentos.forEach(function(med) {
        if (med['id'] === id) {
          medicamento_selec.id = id;
          medicamento_selec.nombre = med['name'];
        }
      });
      this.datos_receta.medicamento = medicamento_selec;
      this.esta_modificando = true;
    },

    generarVistaPrevia(print) {
      this.alerta.texto = '';
      if (this.datos_receta.medicamento.nombre === '') {
        this.alerta.texto = this.$t('recetas.required.medicamento');
      } else if (this.receta.paciente.id === 0) {
        this.alerta.texto = this.$t('recetas.required.paciente');
      }

      if (this.alerta.texto !== '') {
        this.snackbar = true;
        this.alerta.color = 'warning';
        this.alerta.icono = 'mdi-alert-circle';
      } else if (!this.disabledButton) {
        this.disabledButton = true;
        this.loading = true;
        this.$http
          .post(
            generarVistaPreviaRecetaUrl,
            { user_id: this.authUser.id, receta: this.receta, datosreceta: this.datos_receta, print: print },
            { headers: getHeader() }
          )
          .then(response => {
            this.esta_modificando = false;
            if (response.data.estado === '1') {
              this.alerta.texto = print
                ? this.$t('recetas.imprimida_correctamente')
                : this.$t('recetas.creada_correctamente');
              this.alerta.icono = print ? 'mdi-print' : 'mdi-send';
              this.alerta.color = 'success';
              if (response.data.url !== '' && print) {
                window.open(response.data.url);
              }
              window.localStorage.setItem('mensaje_ehealth', JSON.stringify(this.alerta));
              this.$router.push('/paciente/' + this.receta.paciente.id);
            } else {
              this.alerta.texto = this.$t('errors.try_again');
              this.alerta.icono = 'mdi-alert-circle';
              this.alerta.color = 'error';
            }
            this.snackbar = true;
            this.disabledButton = false;
            this.loading = false;
          });
      }
    },
    volverAtras() {
      if (this.$route.params.id !== undefined) {
        this.$router.push('/paciente/' + this.$route.params.id);
      } else {
        this.$router.go(-1);
      }
    },
    modificaDato() {
      this.esta_modificando = true;
    },
    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },
    onChangeSearchVal() {
      let searchString = this.datos_receta.medicamento.nombre;
      if (!searchString || searchString.trim().length < 3) {
        return;
      }

      this.$_debounceMixin_debounce(() => {
        searchString = searchString.trim();
        this.buscaMedicamentosReceta();
      }, 600);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.esta_modificando && to['name'] !== 'Login') {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="css">
.tabla_receta {
  width: 99%;
  border-collapse: collapse;
}
.contenido_receta {
  width: 100%;
  border-collapse: collapse;
}
.tabla_receta,
.tabla_receta tbody,
.tabla_receta tr,
.tabla_receta td {
  border: #0a2953 2px solid;
}
.contenido_receta,
.contenido_receta tbody,
.contenido_receta tr,
.contenido_receta td {
  border: #0a2953 0px solid;
}
.texto-vertical-2 {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-weight: bolder;
}
.celda_1_1 {
  width: 20px;
  height: 400px;
}
.td_prescripcion {
  vertical-align: top;
  width: 38%;
  padding: 5px;
}
.texto_prescripcion {
  text-align: justify;
  font-size: 12px;
}
.td_duracion {
  vertical-align: top;
  text-align: center;
  width: 25%;
}
.td_paciente {
  width: 37%;
  vertical-align: top;
}
.celda_interior {
  height: 65px;
}
.borde_derecho {
  border-right: #0a2953 2px solid !important;
}
.borde_suelo {
  border-bottom: #0a2953 2px solid !important;
}

.borde_arriba {
  border-top: #0a2953 2px solid !important;
}
.celda_paciente {
  height: 155px;
  vertical-align: top;
  padding: 5px;
}
.td_informacion_farmaceutico {
  height: 100px;
  vertical-align: top;
  padding: 0px 5px;
}
.firma_receta {
  max-width: 130px;
  float: right;
  z-index: 2;
  margin-top: -20px;
}
.datos_profesional {
  font-size: 11px;
  color: #6c6c6c;
}
.datos_paciente {
  color: #6c6c6c;
}
.input-numero {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  width: 80px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-numero-mobile {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  width: 80px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-texto-corto {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  width: 120px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: center;
  margin-bottom: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-texto-mobile {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  width: 120px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-separation {
  margin-right: 10px;
}
</style>
